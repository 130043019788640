@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@font-face {
  font-family: 'Maragsa';
  font-display: swap;
  src: url("../fonts/maragsa-display.woff") format("woff");
}

*, body {
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 10px;
}

body {
  overflow: -moz-scrollbars-vertical;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
  height: 0px;
}

.main-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
}

.loader {
  margin: auto;
  border: 8px solid #EF946C;
  border-top: 8px solid #f44336;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

a .home-link {
  color: black;
  font-family: 'Poppins', sans-serif;
}

.link {
  text-decoration: none;
  color: #f44336;
  font-size: 1em;
}

h2 {
  font-size: 15.4em;
  font-family: 'Maragsa', 'Poppins', sans-serif;
  color: #161925;
}

h3 {
  font-size: 4.8em;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #161925;
}

h4 {
  font-size: 2.5em;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
}

h5 {
  font-size: 5.4em;
  font-family: 'Maragsa', 'Poppins', sans-serif;
  margin-bottom: 20px;
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 2.4em;
}

ul {
  list-style-type: none;
  padding: 0;
}

nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 100px 0 30px;
  position: relative;
  z-index: 5;
}

nav .small-title {
  display: none;
}

nav .checkbox {
  position: absolute;
  display: block;
  height: 42px;
  width: 42px;
  top: 44px;
  right: 60px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

nav .hamburger-lines {
  display: block;
  height: 34px;
  width: 42px;
  position: absolute;
  top: 44px;
  right: 60px;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

nav .hamburger-lines .line {
  display: block;
  height: 6px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

nav .hamburger-lines .line1 {
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

nav .hamburger-lines .line2 {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

nav .hamburger-lines .line3 {
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

nav .back-lines {
  display: block;
  height: 34px;
  width: 42px;
  position: absolute;
  top: 44px;
  right: 60px;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

nav .back-lines .line {
  display: block;
  height: 6px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

nav .back-lines .line1 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

nav .back-lines .line2 {
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

nav .back-lines .line3 {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.sidebar-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: left;
  background-color: #F7F7F7;
  height: 100vh;
  width: 100%;
  padding: 12rem;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: translate(150%);
          transform: translate(150%);
  z-index: 1;
}

.sidebar-menu .link h3 {
  font-weight: 500;
  background: none;
  -webkit-transition: background-color .3s ease-in-out;
  transition: background-color .3s ease-in-out;
  color: #161925;
}

.sidebar-menu .link h3:hover {
  color: #f44336;
}

nav input[type="checkbox"]:checked ~ .sidebar-menu {
  -webkit-transform: translateX(60%);
          transform: translateX(60%);
}

nav input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

nav input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

nav input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

nav input[type="checkbox"]:checked ~ .back-lines .line1 {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

nav input[type="checkbox"]:checked ~ .back-lines .line2 {
  -webkit-transform: scaleY(100%);
          transform: scaleY(100%);
}

nav input[type="checkbox"]:checked ~ .back-lines .line3 {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 50vh;
  max-width: 50vw;
  margin: 30px;
  line-height: 0.98;
}

@media all and (max-width: 649px) {
  nav .title {
    display: none;
  }
  nav .small-title {
    display: block;
  }
  nav .checkbox {
    position: absolute;
    display: block;
    height: 42px;
    width: 42px;
    top: 36px;
    right: 30px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }
  nav .hamburger-lines {
    display: block;
    height: 24px;
    width: 30px;
    position: absolute;
    top: 36px;
    right: 30px;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  nav .hamburger-lines .line {
    height: 4px;
  }
  .sidebar-menu {
    text-align: center;
    padding: 4rem;
    z-index: -1;
  }
  nav input[type="checkbox"]:checked ~ .sidebar-menu {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  nav .back-lines {
    display: block;
    height: 24px;
    width: 30px;
    position: absolute;
    top: 36px;
    right: 30px;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  nav .back-lines .line {
    height: 4px;
  }
  h2 {
    font-size: 6.5em;
    font-family: 'Maragsa', 'Poppins', sans-serif;
  }
  h3, h5 {
    font-size: 2.4em;
  }
  p {
    font-size: 1.4em;
  }
  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 50vh;
    max-width: 50vw;
    margin: 30px 30px 80px 30px;
    line-height: 0.98;
  }
}

@media all and (min-width: 650px) and (max-width: 959px) {
  h2 {
    font-size: 12em;
    font-family: 'Maragsa', 'Poppins', sans-serif;
  }
  h3, h5 {
    font-size: 3em;
  }
  p {
    font-size: 2em;
  }
  nav .small-title {
    display: none;
  }
}

@media all and (max-width: 900px) and (orientation: landscape) {
  .sidebar-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: left;
    background-color: #F7F7F7;
    height: 100vh;
    width: 100%;
    padding: 6rem;
  }
  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 50vh;
    max-width: 30vw;
    margin: 30px 30px 72px 30px;
    line-height: 0.98;
  }
  footer h2 {
    font-size: 8em;
  }
}
