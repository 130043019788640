@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
@font-face {
  font-family: 'Maragsa';
  font-display: swap;
  src: url('../fonts/maragsa-display.woff')  format('woff');
}

$turquoise: #DFF8EB;
$grey: #F7F7F7;
$redburn: #f44336;
$orange: #EF946C;
$raisin: #161925;
$bdazzledblue: #235789;

*, body {
  margin: 0;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  overflow: -moz-scrollbars-vertical;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
  height: 0px;
}

.main-container {
  width: 100vw;
  height: 100vh;
  position:fixed;
  left:0;
  right: 0;
}

.loader {
  margin: auto;
  border: 8px solid #EF946C;
  border-top: 8px solid #f44336;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

// .loader-hide {
//   display: none;
// }

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// HEADER
a .home-link {
    color: black;
    font-family: 'Poppins', sans-serif;
}

.link {
  text-decoration: none;
  color: $redburn;
  font-size: 1em;
}

// FONT
h2 {
  // PROJECT TITLE
  font-size: 15.4em;
  font-family: 'Maragsa', 'Poppins', sans-serif;
  color: $raisin;
}

h3 {
  // MAIN TITLES
  font-size: 4.8em;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: $raisin;
}

h4 {
  font-size: 2.5em;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
}

h5 {
  // LOADER
  font-size: 5.4em;
  font-family: 'Maragsa', 'Poppins', sans-serif;
  margin-bottom: 20px;
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 2.4em;
}

ul {
  list-style-type: none;
  padding: 0;
}

// NAV BAR
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 100px 0 30px;
  position: relative;
  z-index: 5;
}

nav .small-title {
  display: none;
}

nav .checkbox {
  position: absolute;
  display: block;
  height: 42px;
  width: 42px;
  top: 44px;
  right: 60px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

nav .hamburger-lines {
  display: block;
  height: 34px;
  width: 42px;
  position: absolute;
  top: 44px;
  right: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

nav .hamburger-lines .line {
  display: block;
  height: 6px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

nav .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

nav .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

nav .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

nav .back-lines {
  display: block;
  height: 34px;
  width: 42px;
  position: absolute;
  top: 44px;
  right: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

nav .back-lines .line {
  display: block;
  height: 6px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

nav .back-lines .line1 {
  transform: rotate(45deg);
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

nav .back-lines .line2 {
  transform: scaleY(0);
  transition: transform 0.2s ease-in-out;
}

nav .back-lines .line3 {
  transform: rotate(-45deg);
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

// MENU ITEMS
.sidebar-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  background-color: $grey;
  height: 100vh;
  width: 100%;
  padding: 12rem;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: translate(150%);
  z-index: 1;

  .link h3 {
    font-weight: 500;
    background: none;
    transition: background-color .3s ease-in-out;
    color: $raisin;
  }
  
  .link h3:hover {
    color: $redburn;
  }
}

nav input[type="checkbox"]:checked ~ .sidebar-menu {
  transform: translateX(60%);
}

nav input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

nav input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

nav input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

nav input[type="checkbox"]:checked ~ .back-lines .line1 {
  transform: rotate(0deg);
}

nav input[type="checkbox"]:checked ~ .back-lines .line2 {
  transform: scaleY(100%);
}

nav input[type="checkbox"]:checked ~ .back-lines .line3 {
  transform: rotate(0deg);
}

.center {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

// FOOTER
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 50vh;
  max-width: 50vw;
  margin: 30px;
  line-height: 0.98;
}

// RESPONSIVENESS
@media all and (max-width: 649px) {
  nav .title {
    display: none;
  }

  nav .small-title {
    display: block;
  }

  nav .checkbox {
    position: absolute;
    display: block;
    height: 42px;
    width: 42px;
    top: 36px;
    right: 30px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }
  
  nav .hamburger-lines {
    display: block;
    height: 24px;
    width: 30px;
    position: absolute;
    top: 36px;
    right: 30px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  nav .hamburger-lines .line {
    height: 4px;
  }

  .sidebar-menu {
    text-align: center;
    padding: 4rem;
    z-index: -1;
  }

  nav input[type="checkbox"]:checked ~ .sidebar-menu {
    transform: translateX(0%);
  }

  nav .back-lines {
    display: block;
    height: 24px;
    width: 30px;
    position: absolute;
    top: 36px;
    right: 30px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  nav .back-lines .line {
    height: 4px;
  }

  h2 {
    // PROJECT TITLE
    font-size: 6.5em;
    font-family: 'Maragsa', 'Poppins', sans-serif;
  }

  h3, h5 {
    font-size: 2.4em;
  }

  p {
    font-size: 1.4em;
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 50vh;
    max-width: 50vw;
    margin: 30px 30px 80px 30px;
    line-height: 0.98;
  }
}

@media all and (min-width: 650px) and (max-width: 959px) {
  h2 {
    // PROJECT TITLE
    font-size: 12em;
    font-family: 'Maragsa', 'Poppins', sans-serif;
  }

  h3, h5 {
    font-size: 3em;
  }

  p {
    font-size: 2em;
  }

  nav .small-title {
    display: none;
  }
}

@media all and (max-width: 900px) and (orientation: landscape) {
  .sidebar-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background-color: $grey;
    height: 100vh;
    width: 100%;
    padding: 6rem;

  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 50vh;
    max-width: 30vw;
    margin: 30px 30px 72px 30px;
    line-height: 0.98;

    h2 {
      font-size: 8em;
    }
  }
}